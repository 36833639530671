import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import AddProject from "../components/AddProject";
import AuthContext from "../AuthContext";
import Axios from "../api/api";
import { toast } from "react-toastify";

function Projects() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const [projectModalSetting, setProjectModalSetting] = useState({
    isEdit: false,
    showModal: false,
    currentProject: null,
  });
  const [projects, setAllProjectsData] = useState([]);
  const [updatePage, setUpdatePage] = useState(true);

  useEffect(() => {
    fetchProjectsData();
  }, [updatePage]);

  // Fetching Data of All Projects
  const fetchProjectsData = () => {
    Axios(auth)
      .get("/api/projects/get")
      .then((resp) => {
        setAllProjectsData(resp.data);
      })
      .catch((err) => {
        setAllProjectsData([]);
        navigate("/login");
      });
  };

  // Modal for Project Add
  const addProjectModalSetting = () => {
    setProjectModalSetting({
      isEdit: false,
      showModal: true,
      currentProject: null,
    });
  };

  const hideProjectModal = () => {
    setProjectModalSetting({ ...projectModalSetting, showModal: false });
  };

  // Handle Page Update
  const handlePageUpdate = () => {
    setUpdatePage(!updatePage);
  };

  // Handle Page Update
  const handleClickDelete = (id) => {
    if (window.confirm("Click OK to delete the project")) {
      Axios(auth)
        .delete(`/api/projects/delete/` + id)
        .then((res) => {
          if (res.status == 200) {
            toast("The item is deleted", { type: "success" });
            handlePageUpdate();
          } else {
            toast("Operation failed", { type: "error" });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  // Modal for Project Edit
  const handleClickEdit = (project) => {
    setProjectModalSetting({
      isEdit: true,
      showModal: true,
      currentProject: project,
    });
  };

  // Modal for Project Open
  const handleClickOpen = (project) => {
    navigate("/projects/" + project._id);
  };

  return (
    <>
      <div className="flex flex-col gap-5 w-full">
        {projectModalSetting.showModal && (
          <AddProject
            hideProjectModal={hideProjectModal}
            handlePageUpdate={handlePageUpdate}
            isEdit={projectModalSetting.isEdit}
            currentProject={projectModalSetting.currentProject}
            onAddProject={() => auth.updateLimit(auth.userData.limit - 1)}
          />
        )}
        {/* Table  */}
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 p-5">
          <div className="flex justify-between">
            <div className="flex gap-4 justify-center items-center px-3">
              <div className="font-bold">
                <span className="text-xl">Projects</span>
                {auth.userData.role === "admin" ? (
                  ""
                ) : (
                  <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 ml-2">
                    {" "}
                    Limit: {auth.userData.limit}
                  </span>
                )}
              </div>
            </div>
            <div className="flex gap-4 mt-5">
              {auth.userData.limit > 0 || auth.userData.role === "admin" ? (
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-sm rounded"
                  onClick={addProjectModalSetting}
                >
                  Add Project
                </button>
              ) : (
                <span className="underline underline-offset-2">
                  Please contact administrator to extend limits
                </span>
              )}
            </div>
          </div>
          <table className="min-w-full divide-y-2 divide-gray-200 text-sm mt-2">
            <thead>
              <tr>
                {auth?.userData?.role === "admin" ? (
                  <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                    User
                  </th>
                ) : (
                  ""
                )}
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Image
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Project Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Description
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Last Updated
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Action
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {projects.map((project, index) => {
                return (
                  <tr key={project._id}>
                    {auth?.userData?.role === "admin" ? (
                      <td className="px-4 py-2">{project?.user?.name}</td>
                    ) : (
                      ""
                    )}
                    <td className="px-4 py-2">
                      {project?.image ? (
                        <img
                          src={project?.image}
                          alt={project?.name}
                          className="w-16 border-1"
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {project?.name}
                    </td>
                    <td className="px-4 py-2 text-gray-700">
                      {project?.description}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {new Date(project.updatedAt).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-2 text-gray-700">
                      <div className="flex items-center">
                        <img
                          src={require("../assets/view.png")}
                          className="w-7 cursor-pointer mr-2"
                          alt="edit"
                          onClick={() => handleClickOpen(project)}
                        />
                        <img
                          src={require("../assets/editing.png")}
                          className="w-7 cursor-pointer mr-2"
                          alt="edit"
                          onClick={() => handleClickEdit(project)}
                        />
                        <img
                          src={require("../assets/delete.png")}
                          className="w-7 cursor-pointer"
                          alt="delete"
                          onClick={() => handleClickDelete(project._id)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Projects;
